.review-form{
    background-color: #F3F3F3 !important;
    padding-left: 100px;
    padding-top: 100px;
}

.highlight {
  background-color: yellow;
  transition: background-color 0.5s ease-in-out;
}
.table-custom {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    background-color: #F3F3F3 !important; /* Light background color */
  }
  
  .table-custom th,
  .table-custom td {
    padding: 12px 15px;
    border: 1px solid #F3F3F3 !important;
  }
  
  .table-custom thead th {
    background-color: #343a40; /* Dark header background color */
    color: #ffffff;
  }
  
  .table-custom tbody tr:nth-of-type(even) {
    background-color: #F3F3F3 !important; /* Even row background color */
  }
  
  .table-custom tbody tr:hover {
    background-color: #F3F3F3 !important; /* Hover row background color */
  }
  
  .file-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
