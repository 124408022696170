/* Common styles */

/* .container {
  display: flex;
  width: 100%;
} */

.half-page-container {
  flex: 3;
  /* Make the left side bigger */
  padding: 20px;
  background-color: #f0f0f0;
  /* Example background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  /* Add margin between the divs */
}

.full-width-container {
  flex: 2;
  /* Takes less space */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
  /* Adjust as needed */
  height: fit-content;
  overflow: scroll;
}

.centered-content {
  text-align: center;
}

.header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.divider {
  height: 2px;
  background-color: #ddd;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.333% - 20px);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #007BFF;
}

.card p {
  font-size: 1rem;
  color: #666;
}

.containerDivTwo {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.editor-container {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  transition: all 0.3s ease;
}

.editor-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.notes-container {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-top: 20px;
  /* Add some margin at the top */
}

.notes-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.note {
  margin-bottom: 10px;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

.note div {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* .button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .button:hover {
    background: #0056b3;
  } */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}