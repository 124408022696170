.expiring {
    background-color: #DD2E18;
    color: white !important;
    font-weight: 700 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
    opacity: 1 !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.Mui-disabled {
    opacity: 1 !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.warning {
    background-color: #FFAB00;
    color: white !important;
    font-weight: 700 !important;    
}

.strikethrough {
    text-decoration: line-through;
}

.noeditfield input {
    background-color: rgba(128, 128, 128, 0.3); /* Grey color with 30% opacity */
}

.word-break-cell {
    white-space: normal !important;
    word-break: break-word; /* You can also use 'break-all' if needed */
}